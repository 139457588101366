import React, { useState, useEffect } from 'react';
import {
  Descriptions,
  Typography,
  message,
  Card,
  Row,
  Col,
  Image,
  Tag,
  Divider,
} from 'antd';
import moment from 'moment';
import { getListFromServer } from '../../lib/apis';
import { AGENT_USER_BALL_MAUNG_DETAIL } from '../../lib/config';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const DescriptionItem = ({ label, value, itemStyle }) => {
  return (
    <Descriptions style={itemStyle} column={1}>
      <Descriptions.Item label={<Text className="text-success">{label}</Text>}>
        <Text>{value}</Text>
      </Descriptions.Item>
    </Descriptions>
  );
};

const formatDateTime = (dateTime, type) => {
  return type === 'date'
    ? moment(dateTime).format('MMM DD YYYY')
    : moment(dateTime).format('h:mm A');
};

const renderTeamDetails = (team, isKeyTeam) => (
  <Col
    span={8}
    style={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Image src={team?.logo} width={56} preview={false} />
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
      <div style={{ flex: 1 }}>
        <Text>{team?.name || '....'}</Text>
      </div>
      <div style={{ marginLeft: '8px' }}>
        {isKeyTeam && <Text className="text-success">{team?.keyMatch}</Text>}
      </div>
    </div>
  </Col>
);

const BallMaungDetail = ({ data }) => {
  const { access_token } = useSelector((state) => state.user);
  const [ballMaungDetail, setBallMaungDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataList = async () => {
    setLoading(true);
    await getListFromServer(
      `${AGENT_USER_BALL_MAUNG_DETAIL}/${data.id}`,
      access_token
    )
      .then((res) => {
        setLoading(false);
        setBallMaungDetail(res.data);
        console.log('maung d....', res.data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || 'Connection Error');
      });
  };

  useEffect(() => {
    getDataList();
  }, []);

  if (!data || !ballMaungDetail) return null;

  return (
    <>
      <DescriptionItem label="ID" value={data.id} />
      <DescriptionItem label="Game Type" value="Maung" />
      <DescriptionItem
        label="Tax"
        value={data.win_cut_percent}
      />

      <DescriptionItem
        label="Is Finish?"
        value={data.is_finish ? 'Yes' : 'No'}
      />
      <DescriptionItem
        label="Is Win?"
        value={data.is_finish ? (data.is_win ? 'နိုင်' : 'ရှုံး') : '-'}
      />
      <DescriptionItem
        label="Final Point"
        value={data.final_point}
        itemStyle={{ marginTop: '30px' }}
      />
      <DescriptionItem label="Bit Amount" value={data.bit_amount || '-'} />
      <DescriptionItem
        label="Bet Time"
        value={formatDateTime(data.created_at, 'time')}
      />
      <DescriptionItem
        label="Updated At"
        value={formatDateTime(data.updated_at, 'time')}
      />

      {ballMaungDetail.map((item, index) => {
        return (
          <div key={index}>
            {item.bet_maung.map((betItem, betIndex) => {
              const keyTeamName =
              betItem.team_id === betItem.match?.team_one.id ? betItem.match?.team_one.name_en : betItem.match?.team_two.name_en;

              const keyOddsChange = () => {
                if (betItem.match?.team_one.key_word || betItem.match?.team_two.key_word) {
                  return betItem.key;
                }
              };

              return (
                <Card
                  loading={loading}
                  key={`${index}-${betIndex}`}
                  title={formatDateTime(betItem.match.match_time, "time")}
                  className="bg-card mt-5"
                >
                  <Row align="middle">
                    {renderTeamDetails(
                      {
                        logo: betItem.match?.team_one.logo,
                        name: betItem.match?.team_one.name_en,
                        keyMatch: keyOddsChange(),
                      },
                      betItem.bet_team.id === betItem.match?.team_one.id
                    )}
                    <Col
                      span={8}
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Tag
                        className="goal-card py-1 px-2 rounded-lg"
                        style={{ fontSize: '16px' }}
                      >
                        {betItem.team_one_goal} : {betItem.team_two_goal}
                      </Tag>
                      <Tag
                        className="bg-success py-1 px-2 rounded-lg"
                        style={{ marginTop: '8px' }}
                      >
                        {betItem.goalPlus}
                      </Tag>
                    </Col>
                    {renderTeamDetails(
                      {
                        logo: betItem.match?.team_two.logo,
                        name: betItem.match?.team_two.name_en,
                        keyMatch: keyOddsChange(),
                      },
                      betItem.bet_team.id === betItem.match?.team_two.id
                    )}
                  </Row>
                  <Divider style={{ borderColor: 'white' }} className="my-4" />
                  <Row className="flex justify-between">
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <Text className="text-success">Selected</Text>
                      {betItem.bet_type === "KEY" && (
                        <Text style={{ marginTop: "5px" }}>{keyTeamName}</Text>
                      )}
                      {betItem.bet_type === "GOAL_UP" && betItem.team_id === betItem.match?.team_one.id && (
                        <Text>GOAL_UP</Text>
                      )}
                      {betItem.bet_type === "GOAL_UP" && betItem.team_id !== betItem.match?.team_one.id && (
                        <Text>GOAL_DOWN</Text>
                      )}
                      {betItem.bet_type === 'GOAL_UP' &&
                        betItem.team_id === betItem.match?.team_one.id && (
                          <Text>GOAL_UP</Text>
                        )}
                      {betItem.bet_type === 'GOAL_UP' &&
                        betItem.team_id !== betItem.match?.team_one.id && (
                          <Text>GOAL_DOWN</Text>
                        )}
                    </Col>
                    <Col>
                      <Text>Match Cancel : </Text>
                      <Text>{betItem.match?.cancel_match ? 'Yes' : 'No'}</Text>
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default BallMaungDetail;
