import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeleteDataOnServer, GetDataListFromServer } from "../lib/apis";
import {
  CMS_ACCESS_LEVEL_API,
  CMS_ACCESS_LEVEL_DELETE_API,
} from "../lib/config";
import {
  Button,
  Col,
  Drawer,
  Popconfirm,
  Row,
  Table,
  Tag,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { queryHandler } from "../lib/globle_functions";
import { QUERY_ACCESS_PERMISSION } from "../lib/query_objects";
import AccessLevelForm from "../components/accessLevel/form";
import { PERMISSION_COLORS } from "../lib/global_consts";

export default function AccessLevelPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    name: "",
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      CMS_ACCESS_LEVEL_API,
      queryHandler(filter, QUERY_ACCESS_PERMISSION, {page_number,per_page}),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function deletePermisions(id) {
    setLoading(true);
    await DeleteDataOnServer(CMS_ACCESS_LEVEL_DELETE_API, id, access_token)
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      render: (accessPermission) => (
        <>
          {accessPermission.map((permi) => (
            <Tag
              key={permi}
              bordered={false}
              color={PERMISSION_COLORS[permi]}
              style={{ margin: "4px" }}
              size="large"
            >
              {permi}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "",
      render: (_, access) => (
        <Row wrap={false} gutter={16} justify={"end"}>
          <Col>
            <Button
              size={ui_size}
              type="primary"
              onClick={() => {
                setEdit(access);
                setShowForm(true);
              }}
            >
              Edit
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title="Delete Access Permisison"
              description="Are you sure to delete this Access Permisison, If permission on used can't delete, Delete Permission can't recover"
              onConfirm={() => {
                deletePermisions(access.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size={ui_size} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }

    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("ACCESS_PERMISSION_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New User
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        size={ui_size}
        open={showForm}
        onClose={() => closeDrawer()}
        title={edit ? "Edit Permisison" : "New Permission"}
        destroyOnClose
        placement="right"
        width={"1200px"}
      >
        <AccessLevelForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
