import { useEffect, useState } from "react";
import { Row, Col, Menu } from "antd";
import shanTable from "./table_builder";
import "./shan_table.css";

export default function ShanGameDetailUi({ data, user }) {
  const [roomState, setRoomState] = useState("start");
  const [dealerDo, setDealerDo] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const dealer = data?.players.find(
      (value) => value && value.player_index === data.dealer_index
    );
          
    switch (true) {
      case dealer && !dealer.cards.third_card && dealer.cards.value > 7:
        setDealerDo(true);
        setMenuItems([
          { label: "Start", key: "start" },
          { label: "End", key: "end" },
        ]);
        break;
      case data.flip && !dealer.cards.third_card:
        setDealerDo(false);
        setMenuItems([
          { label: "Start", key: "start" },
          { label: "Caught", key: "caught" },
          { label: "End", key: "end" },
        ]);
        break;
      case data.flip && dealer.cards.third_card:
        setDealerDo(false);
        setMenuItems([
          { label: "Start", key: "start" },
          { label: "Caught", key: "caught" },
          { label: "DealerPull", key: "dealerPull" },
          { label: "End", key: "end" },
        ]);
        break;
      default:
        setDealerDo(false);
        setMenuItems([
          { label: "Start", key: "start" },
          { label: "End", key: "end" },
        ]);
        break;
    }
  }, []);

  console.log('menu item', menuItems)

  function displayStateHandler() {
    return (
      <Menu
        style={{width:'1052px'}}
        onClick={(element) => setRoomState(element.key)}
        selectedKeys={[roomState]}
        mode="horizontal"
        items={menuItems}
      />
    );
  }

  return (
    <div className="room">
      <div className="room_info">
        <Row>
          <Col>{displayStateHandler()}</Col>
        </Row>
      </div>
      {shanTable(data, roomState, dealerDo)}
      <div className="room_footer"></div>
    </div>
  );
}
