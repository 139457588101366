import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GAME_ABC_USER_LOG_API, USER_DELETE_API } from "../../lib/config";
import { QUERY_ABC_USER_LOGS } from "../../lib/query_objects";
import { GetDataListFromServer } from "../../lib/apis";
import { queryHandler } from "../../lib/globle_functions";
import {
  Card,
  Col,
  message,
  Tag,
  Typography,
  Button,
  Spin,
  Row,
  DatePicker,
  Table,
  Modal,
} from "antd";
import "./abc_table.css";
import moment from "moment";
import dayjs from "dayjs";
import AbcGameDetail from "./abc_game_detail";

const { Text } = Typography;

export default function AbcUserLogs({ data, closeDrawer }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [detailVisible, setDetailVisible] = useState(false);
  const [filter, setFilter] = useState({
    target_date: "",
  });
  const [paging, setPaging] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  async function getList(page_number, per_page, updateFilter) {
    await GetDataListFromServer(
      `${GAME_ABC_USER_LOG_API}`,
      queryHandler(
        updateFilter ? updateFilter : { ...filter, user_id: data.id },
        { ...QUERY_ABC_USER_LOGS, user_id: data.id },
        {
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: dataList, total } }) => {
        updateFilter && setFilter(updateFilter);
        setPaging({ ...paging, total, page_number, per_page });
        setList(
          dataList.map((item) => ({ ...item, data: JSON.parse(item.data) }))
        );
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
        setLoading(false);
      });
  }

  useEffect(() => {
    getList(paging.page_number, paging.per_page);
  }, []);

  function cardAmountColumn(title, bitAmount, winAmount) {
    return {
      title,
      render: (_, record) => {
        const targetPlayer = record?.data?.players.find(
          (player) => player.id === record.user_id
        );
        const winColor = targetPlayer?.[winAmount] ? "green" : null;
        return (
          <>
            <Text>{targetPlayer?.[bitAmount]}</Text>
            <Text style={{ marginLeft: "5px", color: winColor }}>
              {targetPlayer?.[winAmount]
                ? `(+${targetPlayer[winAmount]})`
                : null}
            </Text>
          </>
        );
      },
    };
  }

  const columns = [
    {
      title: "Total bit",
      render: (_, record) => {
        const targetPlayer = record?.data?.players.find(
          (player) => player.id === record.user_id
        );
        return <Text>{targetPlayer.total_bit}</Text>;
      },
    },
    cardAmountColumn("A-Bit Amount", "total_bit_A", "total_win_A"),
    cardAmountColumn("B-Bit Amount", "total_bit_B", "total_win_B"),
    cardAmountColumn("C-Bit Amount", "total_bit_C", "total_win_C"),
    cardAmountColumn("D-Bit Amount", "total_bit_D", "total_win_D"),
    {
      title: "Cut Percent",
      dataIndex: "win_cut_percent",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("HH:mm:ss DD-MM-YYYY"),
    },
    {
      title: "",
      dataIndex: "id",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => openDetailModal(record)}
          size={ui_size}
        >
          Detail
        </Button>
      ),
    },
  ];

  function renderFilterSection() {
    return (
      <Row
        gutter={16}
        align="middle"
        style={{ marginLeft: "10px", marginRight: "10px" }}
      >
        <Col span={6}>
          <Row align="middle">
            <Col span={4}>
              <Text>Date</Text>
            </Col>
            <Col span={16}>
              <DatePicker
                allowClear={false}
                style={{ width: "100%" }}
                format={"YYY-MM-DD"}
                value={
                  filter.target_date
                    ? dayjs(filter.target_date, "YYYY-MM-DD")
                    : null
                }
                onChange={(e) => {
                  setFilter({ ...filter, target_date: e });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={18} style={{ textAlign: "right", gap: "10px" }}>
          <Button
            size={ui_size}
            loading={loading}
            type="primary"
            style={{ marginRight: "10px" }}
          >
            Clear
          </Button>
          <Button
            size={ui_size}
            loading={loading}
            type="primary"
            onClick={() => getList(paging.page_number, paging.per_page)}
          >
            Search
          </Button>
        </Col>
      </Row>
    );
  }

  function openDetailModal(record) {
    setDetail({ ...record, user_data: data });
    setDetailVisible(true);
  }

  function closeDetailModal() {
    setDetail(null);
    setDetailVisible(false);
  }

  function detailHandler() {
    if (!detail) return <div>No details available!!!</div>;

    return <AbcGameDetail data={detail} />
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>{renderFilterSection()}</Col>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={list}
            size={ui_size}
            style={{ marginTop: "12px" }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={{
              total: paging.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getList(pageNumber, perPage);
              },
            }}
          ></Table>
        </Col>
      </Row>
      <Modal
        open={detailVisible}
        onCancel={closeDetailModal}
        destroyOnClose={true}
        footer={null}
        width={1100}
        title="Abc detail"
      >
        {detailHandler()}
      </Modal>
    </Spin>
  );
}
