import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer } from "../lib/apis";
import { Button, Col, Drawer, Row, Table, Tag, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CMS_AGENT_LIST_API } from "../lib/config";
import { QUERY_CMS_AGENTS } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import AgentDetailPage from "../components/agent/detail";
import TransactionForm from "../components/transactions/form";
import CmsAgentForm from "../components/agent/form";

export default function CmsAgentPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [createTran, setCreateTran] = useState(false);
  const [filter, setFilter] = useState({
    name: "",
    agent_code: "",
    is_locked: "",
    id: "",
    is_blocked: "",
    is_deleted: "",
    is_api_only: "false",
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList(page_number,per_page) {
    setLoading(true);
    await GetDataListFromServer(
      CMS_AGENT_LIST_API,
      queryHandler(filter, QUERY_CMS_AGENTS, {page_number,per_page}),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total ,page_number,per_page});
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number,pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Login Name",
      dataIndex: "login_name",
    },
    { title: "Phone No", align: "center", dataIndex: "phone" },
    {
      title: "Agent Code",
      dataIndex: "agent_code",
      align: "center",
    },
    {
      title: "Points",
      dataIndex: "agent_points",
      align: "center",
    },
    {
      title: "Agent is Locked",
      align: "center",
      dataIndex: "is_locked",
      render: (is_locked) => (
        <Tag color={is_locked ? "red" : "green"}>
          {is_locked ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Agent is Deleted",
      align: "center",
      dataIndex: "is_deleted",
      render: (is_locked) => (
        <Tag color={is_locked ? "red" : "green"}>
          {is_locked ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Button
              size={ui_size}
              disabled={
                user.is_deleted ||
                !permissions.includes("CMS_TRANSACTION_CREATE")
              }
              type="primary"
              onClick={() => setCreateTran(user)}
            >
              Create Transaction
            </Button>
          </Col>
          <Col>
            <Button
              size={ui_size}
              type="primary"
              onClick={() => {
                setDetail(user);
              }}
            >
              Detail
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number,pagin.per_page);
    }
    setCreateTran(false);
    setShowForm(false);
    setDetail(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("CMS_AGENT_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Agent
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => setShowForm(false)}
        title={"New Agent"}
        destroyOnClose
        placement="right"
        width={"800px"}
      >
        <CmsAgentForm edit={false} closeDrawer={closeDrawer} />
      </Drawer>
      <Drawer
        open={!!detail}
        onClose={() => closeDrawer(true)}
        styles={{
          body: { margin: 0, padding: 0 },
          header: { borderBottom: "0px" },
        }}
        destroyOnClose={true}
        title="Agent Detail"
        placement="right"
        width="90vw"
      >
        <AgentDetailPage data={detail} closeDrawer={closeDrawer} />
      </Drawer>
      <Drawer
        open={!!createTran}
        onClose={() => setCreateTran(false)}
        title="Create Transaction"
        destroyOnClose
        placement="right"
        width={"600px"}
      >
        <TransactionForm agent={createTran} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
