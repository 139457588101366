const PPLC_GAME_LIST = [
  { game_id: "2201", game_name: "High Flyer" },
  { game_id: "1301", game_name: "Spaceman" },
  { game_id: "801", game_name: "Mega Wheel" },
  { game_id: "1101", game_name: "Sweet Bonanza CandyLand" },
  { game_id: "402", game_name: "Speed Baccarat 1" },
  { game_id: "403", game_name: "Speed Baccarat 2" },
  { game_id: "441", game_name: "Korean Speed Baccarat 1" },
  { game_id: "701", game_name: "Mega Sic Bo" },
  { game_id: "449", game_name: "Korean Speed Baccarat 2" },
  { game_id: "211", game_name: "Lucky 6 Roulette" },
  { game_id: "401", game_name: "Baccarat 1" },
  { game_id: "459", game_name: "Korean Speed Baccarat 3" },
  { game_id: "204", game_name: "Mega Roulette" },
  { game_id: "450", game_name: "Indonesia Speed Baccarat 1" },
  { game_id: "442", game_name: "Mega Baccarat" },
  { game_id: "206", game_name: "Roulette Macao" },
  { game_id: "1001", game_name: "Dragon Tiger" },
  { game_id: "451", game_name: "Thai Speed Baccarat 1" },
  { game_id: "227", game_name: "Roulette Azure" },
  { game_id: "404", game_name: "Baccarat 2" },
  { game_id: "454", game_name: "Privé Lounge Baccarat 1" },
  { game_id: "488", game_name: "Japanese Speed Baccarat 1" },
  { game_id: "481", game_name: "Chinese Speed Baccarat 1" },
  { game_id: "460", game_name: "Korean Speed Baccarat 4" },
  { game_id: "476", game_name: "Korean Speed Baccarat 5" },
  { game_id: "489", game_name: "Japanese Speed Baccarat 2" },
  { game_id: "490", game_name: "Japanese Speed Baccarat 3" },
  { game_id: "482", game_name: "Chinese Speed Baccarat 2" },
  { game_id: "479", game_name: "Vietnamese Speed Baccarat 1" },
  { game_id: "434", game_name: "Fortune 6 Baccarat" },
  { game_id: "433", game_name: "Super 8 Baccarat" },
  { game_id: "455", game_name: "Privé Lounge Baccarat 2" },
  { game_id: "456", game_name: "Privé Lounge Baccarat 3" },
  { game_id: "457", game_name: "Privé Lounge Baccarat 9" },
  { game_id: "458", game_name: "Privé Lounge Baccarat 5" },
  { game_id: "412", game_name: "Speed Baccarat 3" },
  { game_id: "414", game_name: "Speed Baccarat 5" },
  { game_id: "415", game_name: "Speed Baccarat 6" },
  { game_id: "431", game_name: "Speed Baccarat 7" },
  { game_id: "432", game_name: "Speed Baccarat 8" },
  { game_id: "430", game_name: "Speed Baccarat 9" },
  { game_id: "428", game_name: "Speed Baccarat 10" },
  { game_id: "405", game_name: "Speed Baccarat 14" },
  { game_id: "435", game_name: "Speed Baccarat 16" },
  { game_id: "439", game_name: "Speed Baccarat 17" },
  { game_id: "411", game_name: "Baccarat 5" },
  { game_id: "413", game_name: "Baccarat 6" },
  { game_id: "425", game_name: "Baccarat 7" },
  { game_id: "436", game_name: "Baccarat 9" },
  { game_id: "545", game_name: "VIP Roulette - The Club" },
  { game_id: "240", game_name: "PowerUP Roulette" },
  { game_id: "203", game_name: "Speed Roulette 1" },
  { game_id: "225", game_name: "Auto Roulette" },
  { game_id: "213", game_name: "Korean Roulette" },
  { game_id: "262", game_name: "Vietnamese Roulette" },
  { game_id: "201", game_name: "Roulette Green" },
  { game_id: "237", game_name: "Brazilian Roulette" },
  { game_id: "222", game_name: "German Roulette" },
  { game_id: "224", game_name: "Turkish Roulette" },
  { game_id: "221", game_name: "Russian Roulette" },
  { game_id: "210", game_name: "Auto Mega Roulette" },
  { game_id: "226", game_name: "Speed Auto Roulette" },
  { game_id: "234", game_name: "Spanish Roulette" },
  { game_id: "205", game_name: "Speed Roulette 2" },
  { game_id: "223", game_name: "Italian Roulette" },
  { game_id: "208", game_name: "Turkish Mega Roulette" },
  { game_id: "229", game_name: "Roulette Indian" },
  { game_id: "233", game_name: "Romanian Roulette" },
  { game_id: "1501", game_name: "Vegas Ball Bonanza" },
  { game_id: "1401", game_name: "Boom City" },
  { game_id: "1024", game_name: "Andar Bahar" },
  { game_id: "491", game_name: "Punto Banco Italia Tricolore" },
  { game_id: "4001", game_name: "Football Blitz" },
  { game_id: "901", game_name: "ONE Blackjack" },
  { game_id: "721", game_name: "Privé Lounge 1" },
  { game_id: "546", game_name: "VIP Blackjack 1 - Ruby" },
  { game_id: "530", game_name: "Blackjack 26 - Azure" },
  { game_id: "542", game_name: "Blackjack 34 - The Club" },
  { game_id: "568", game_name: "Speed Blackjack 3 - Ruby" },
  { game_id: "607", game_name: "Speed Blackjack 11 - Azure" },
  { game_id: "3003", game_name: "Blackjack X 3 - Azure" },
  { game_id: "566", game_name: "Speed Blackjack 1 - Ruby" },
  { game_id: "3001", game_name: "Blackjack X 1 - Azure" },
  { game_id: "722", game_name: "Privé Lounge 2" },
  { game_id: "663", game_name: "VIP Blackjack 7" },
  { game_id: "543", game_name: "Blackjack 35 - The Club" },
  { game_id: "517", game_name: "Blackjack 10 - Azure" },
  { game_id: "541", game_name: "Blackjack 33 - The Club" },
  { game_id: "305", game_name: "Blackjack 16" },
  { game_id: "552", game_name: "Blackjack 49 - Ruby" },
  { game_id: "647", game_name: "Speed Blackjack 30 - Emerald" },
  { game_id: "551", game_name: "Blackjack 48 - Ruby" },
  { game_id: "523", game_name: "Blackjack 19 - Azure" },
  { game_id: "3005", game_name: "Blackjack X 5 - Ruby" },
  { game_id: "567", game_name: "Speed Blackjack 2 - Ruby" },
  { game_id: "3006", game_name: "Blackjack X 6 - Azure" },
  { game_id: "591", game_name: "Speed Blackjack 6 - Ruby" },
  { game_id: "3002", game_name: "Blackjack X 2 - Azure" },
  { game_id: "550", game_name: "VIP Blackjack 5 - Ruby" },
  { game_id: "549", game_name: "VIP Blackjack 4 - Ruby" },
  { game_id: "548", game_name: "VIP Blackjack 3 - Ruby" },
  { game_id: "592", game_name: "Speed Blackjack 7 - Ruby" },
  { game_id: "3004", game_name: "Blackjack X 4 - Ruby" },
  { game_id: "604", game_name: "Speed Blackjack 8 - Ruby" },
  { game_id: "3008", game_name: "Blackjack X 8 - Ruby" },
  { game_id: "608", game_name: "Speed Blackjack 12 - Azure" },
  { game_id: "3007", game_name: "Blackjack X 7 - Azure" },
  { game_id: "525", game_name: "Blackjack 21 - Azure" },
  { game_id: "3014", game_name: "Blackjack X 14 - Ruby" },
  { game_id: "560", game_name: "Blackjack 46 - Ruby" },
  { game_id: "3015", game_name: "Blackjack X 15 - Ruby" },
  { game_id: "609", game_name: "Speed Blackjack 14 - Azure" },
  { game_id: "554", game_name: "Blackjack 51 - Ruby" },
  { game_id: "528", game_name: "Blackjack 24 - Azure" },
  { game_id: "553", game_name: "Blackjack 50 - Ruby" },
  { game_id: "516", game_name: "Blackjack 9 - Azure" },
  { game_id: "569", game_name: "Speed Blackjack 4 - Ruby" },
  { game_id: "529", game_name: "Blackjack 25 - Azure" },
  { game_id: "536", game_name: "Blackjack 30 - Azure" },
  { game_id: "544", game_name: "Blackjack 36 - The Club" },
  { game_id: "570", game_name: "Speed Blackjack 5 - Ruby" },
  { game_id: "535", game_name: "Blackjack 31 - Azure" },
  { game_id: "540", game_name: "Blackjack 32 - Azure" },
  { game_id: "558", game_name: "Blackjack 44 - Ruby" },
  { game_id: "3016", game_name: "Blackjack X 16 - Azure" },
  { game_id: "524", game_name: "Blackjack 20 - Azure" },
  { game_id: "3021", game_name: "Blackjack X 21 - Ruby" },
  { game_id: "539", game_name: "Blackjack 27 - Azure" },
  { game_id: "3009", game_name: "Blackjack X 9 - Ruby" },
  { game_id: "563", game_name: "Blackjack 38 - Ruby" },
  { game_id: "3010", game_name: "Blackjack X 10 - Ruby" },
  { game_id: "561", game_name: "Blackjack 47 - Ruby" },
  { game_id: "3024", game_name: "Blackjack X 24 - Azure" },
  { game_id: "537", game_name: "Blackjack 29 - Azure" },
  { game_id: "3025", game_name: "Blackjack X 25 - Azure" },
  { game_id: "527", game_name: "Blackjack 23 - Azure" },
  { game_id: "304", game_name: "Blackjack 15" },
  { game_id: "303", game_name: "Blackjack 14" },
  { game_id: "605", game_name: "Speed Blackjack 9 - Ruby" },
  { game_id: "3017", game_name: "Blackjack X 17 - Azure" },
  { game_id: "564", game_name: "Blackjack 39 - Ruby" },
  { game_id: "3018", game_name: "Blackjack X 18 - Azure" },
  { game_id: "606", game_name: "Speed Blackjack 10 - Ruby" },
  { game_id: "3019", game_name: "Blackjack X 19 - Ruby" },
  { game_id: "565", game_name: "Blackjack 40 - Ruby" },
  { game_id: "3020", game_name: "Blackjack X 20 - Ruby" },
  { game_id: "594", game_name: "Blackjack 52 - Ruby" },
  { game_id: "3046", game_name: "BlackjackX 11 - Emerald" },
  { game_id: "595", game_name: "Blackjack 53 - Ruby" },
  { game_id: "3047", game_name: "BlackjackX 12 - Emerald" },
  { game_id: "596", game_name: "Blackjack 54 - Ruby" },
  { game_id: "3048", game_name: "BlackjackX 13 - Emerald" },
  { game_id: "597", game_name: "Blackjack 55 - Ruby" },
  { game_id: "3049", game_name: "BlackjackX 22 - Emerald" },
  { game_id: "593", game_name: "Blackjack 56 - Ruby" },
  { game_id: "559", game_name: "Blackjack 45 - Ruby" },
  { game_id: "3054", game_name: "BlackjackX 29 - Emerald" },
  { game_id: "555", game_name: "Blackjack 41 - Ruby" },
  { game_id: "3055", game_name: "BlackjackX 30 - Emerald" },
  { game_id: "556", game_name: "Blackjack 42 - Ruby" },
  { game_id: "557", game_name: "Blackjack 43 - Ruby" },
  { game_id: "538", game_name: "Blackjack 28 - Azure" },
  { game_id: "526", game_name: "Blackjack 22 - Azure" },
  { game_id: "513", game_name: "Blackjack 7 - Azure" },
  { game_id: "520", game_name: "Blackjack 8 - Azure" },
  { game_id: "512", game_name: "Blackjack 6 - Azure" },
  { game_id: "618", game_name: "Blackjack 73 - Ruby" },
  { game_id: "619", game_name: "Blackjack 74 - Ruby" },
  { game_id: "902", game_name: "ONE Blackjack 2 - Ruby" },
  { game_id: "3050", game_name: "BlackjackX 23 - Emerald" },
  { game_id: "612", game_name: "Speed Blackjack - 15 Ruby" },
  { game_id: "3051", game_name: "BlackjackX 26 - Emerald" },
  { game_id: "598", game_name: "Blackjack 57 - Azure" },
  { game_id: "3052", game_name: "BlackjackX 27 - Emerald" },
  { game_id: "599", game_name: "Blackjack 58 - Azure" },
  { game_id: "3053", game_name: "BlackjackX 28 - Emerald" },
  { game_id: "613", game_name: "Speed Blackjack - 16 Ruby" },
  { game_id: "600", game_name: "Blackjack 59 - Azure" },
  { game_id: "620", game_name: "Blackjack 64 - Ruby" },
  { game_id: "521", game_name: "Blackjack 17 - Azure" },
  { game_id: "515", game_name: "Blackjack 1 - Azure" },
  { game_id: "519", game_name: "Blackjack 2 - Azure" },
  { game_id: "522", game_name: "Blackjack 18 - Azure" },
  { game_id: "562", game_name: "Blackjack 37 - Ruby" },
  { game_id: "614", game_name: "Speed Blackjack - 17 Ruby" },
  { game_id: "601", game_name: "Blackjack 60 - Azure" },
  { game_id: "602", game_name: "Blackjack 61 - Azure" },
  { game_id: "603", game_name: "Blackjack 62 - Azure" },
  { game_id: "590", game_name: "Blackjack 63 - Azure" },
  { game_id: "621", game_name: "Blackjack 65 - Ruby" },
  { game_id: "626", game_name: "Blackjack 70 - Ruby" },
  { game_id: "301", game_name: "Blackjack 11" },
  { game_id: "511", game_name: "Blackjack 3 - Azure" },
  { game_id: "518", game_name: "Blackjack 5 - Azure" },
  { game_id: "302", game_name: "Blackjack 12" },
  { game_id: "514", game_name: "Blackjack 4 - Azure" },
  { game_id: "615", game_name: "Speed Blackjack - 18 Ruby" },
  { game_id: "616", game_name: "Blackjack 71 - Ruby" },
  { game_id: "617", game_name: "Blackjack 72 - Ruby" },
  { game_id: "658", game_name: "Speed Blackjack 19 - Emerald" },
  { game_id: "659", game_name: "Speed Blackjack 20 - Emerald" },
  { game_id: "660", game_name: "Speed Blackjack 21 - Emerald" },
  { game_id: "661", game_name: "Speed Blackjack 22 - Emerald" },
  { game_id: "641", game_name: "Speed Blackjack 24 - Emerald" },
  { game_id: "642", game_name: "Speed Blackjack 25 - Emerald" },
  { game_id: "643", game_name: "Speed Blackjack 26 - Emerald" },
  { game_id: "644", game_name: "Speed Blackjack 27 - Emerald" },
  { game_id: "645", game_name: "Speed Blackjack 28 - Emerald" },
  { game_id: "646", game_name: "Speed Blackjack 29 - Emerald" },
  { game_id: "681", game_name: "Speed Blackjack 31 - Emerald" },
  { game_id: "682", game_name: "Speed Blackjack 32 – Emerald" },
  { game_id: "687", game_name: "Speed Blackjack 33 - Emerald" },
  { game_id: "688", game_name: "Speed Blackjack 34 - Emerald" },
  { game_id: "686", game_name: "Speed Blackjack 35 - Emerald" },
  { game_id: "684", game_name: "Speed Blackjack 36 - The Club" },
  { game_id: "685", game_name: "Speed Blackjack 37 - The Club" },
  { game_id: "904", game_name: "Turkish ONE Blackjack" },
  { game_id: "321", game_name: "Speed Blackjack 23 - Turkish" },
  { game_id: "322", game_name: "Blackjack 75 - Turkish" },
  { game_id: "323", game_name: "VIP Blackjack 14 - Turkish" },
  { game_id: "730", game_name: "Blackjack 75 - Azure" },
  { game_id: "731", game_name: "Blackjack 76 - Azure" },
  { game_id: "676", game_name: "Blackjack 79 - Emerald" },
  { game_id: "677", game_name: "Blackjack 80 - Emerald" },
  { game_id: "678", game_name: "Blackjack 81 - Emerald" },
  { game_id: "673", game_name: "Blackjack 82 - Emerald" },
  { game_id: "674", game_name: "Blackjack 83 - Emerald" },
  { game_id: "675", game_name: "Blackjack 84 - Emerald" },
  { game_id: "683", game_name: "Blackjack 85 – Emerald" },
  { game_id: "662", game_name: "VIP Blackjack 6" },
  { game_id: "547", game_name: "VIP Blackjack 2 - Ruby" },
  { game_id: "664", game_name: "VIP Blackjack 8" },
  { game_id: "622", game_name: "VIP Blackjack 9 - Ruby" },
  { game_id: "623", game_name: "VIP Blackjack 10 - Ruby" },
  { game_id: "624", game_name: "VIP Blackjack 11 - Ruby" },
  { game_id: "625", game_name: "VIP Blackjack 12 - Ruby" },
  { game_id: "671", game_name: "VIP Blackjack 13 – Emerald" },
  { game_id: "672", game_name: "VIP Blackjack 14 - Emerald" },
  { game_id: "723", game_name: "Privé Lounge 3" },
  { game_id: "724", game_name: "Privé Lounge 4" },
  { game_id: "725", game_name: "Privé Lounge 5" },
  { game_id: "747", game_name: "Privé Lounge Blackjack 6" },
  { game_id: "748", game_name: "Privé Lounge Blackjack 7" },
  { game_id: "749", game_name: "Privé Lounge Blackjack 8" },
  { game_id: "750", game_name: "Privé Lounge Blackjack 9" },
  { game_id: "751", game_name: "Privé Lounge Blackjack 10" },
  { game_id: "114", game_name: "Asian Games" },
  { game_id: "422", game_name: "Baccarat 3" },
  { game_id: "426", game_name: "Baccarat 8" },
  { game_id: "104", game_name: "Baccarat Lobby" },
  { game_id: "1320", game_name: "Big Bass Crash" },
  { game_id: "103", game_name: "Blackjack Lobby" },
  { game_id: "110", game_name: "D & W" },
  { game_id: "108", game_name: "Dragon Tiger" },
  { game_id: "105", game_name: "Gameshows" },
  { game_id: "101", game_name: "Live Casino Lobby" },
  { game_id: "111", game_name: "Other Promos" },
  { game_id: "123", game_name: "Power Ball" },
  { game_id: "102", game_name: "Roulette Lobby" },
  { game_id: "230", game_name: "Roulette Ruby" },
  { game_id: "107", game_name: "Sic Bo" },
  { game_id: "109", game_name: "Sic Bo & Dragon Tiger" },
  { game_id: "711", game_name: "Sicbo" },
  { game_id: "424", game_name: "Speed Baccarat 11" },
  { game_id: "421", game_name: "Speed Baccarat 12" },
  { game_id: "438", game_name: "Speed Baccarat 13" },
  { game_id: "427", game_name: "Speed Baccarat 15" }]

export { PPLC_GAME_LIST }
