import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataFromServer, GetDataListFromServer, UpdateDateOnServer } from "../lib/apis";
import {
  Alert,
  Button,
  Col,
  Drawer,
  InputNumber,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { VerifiedOutlined } from "@ant-design/icons";
import {
  GAME_TWOD_AGENT_API,
  GAME_TWOD_AGENT_ONGOING_LIST_API,
  GAME_TWOD_AGENT_UPDATE_LIST_API,
} from "../lib/config";
import dayjs from "dayjs";
import TwodEditFrom from "../components/game_twod_threed/twod_edit_form";
import TwoDetailPage from "../components/game_twod_threed/twodDetail";
import TwodHeadPage from "../components/game_twod_threed/twodHead";
import { moneyFormat } from "../lib/globle_functions";
import TwodAgentEditForm from "../components/game_twod_threed/twod_agent_edit_form";

const { Text } = Typography;

export default function AgentTwodPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [head, setHead] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false)
  const [twodAGent, setTwodAGent] = useState(false)
  const [showAgent, setShowAgent] = useState(false)
  const [limit, setLimit] = useState(null)


  async function closeAGent() {
    setShowAgent(false)
    getAgentTwod()
    getDataList(pagin.page_number, pagin.per_page)
  }

  async function updateSetTimeLimit(data) {

    if (limit < 1000) {
      message.error("Limit must be greater than 1000")
      return;
    }

    const twod = data.twod.map(value => ({
      ...value,
      max_bit: value.is_hot ? value.max_bit : limit,
      max: value.is_hot ? value.max : limit
    }))

    setLoading(true);
    await UpdateDateOnServer(GAME_TWOD_AGENT_UPDATE_LIST_API,
      data.id,
      { id: data.id, twod },
      access_token
    )
      .then(() => {
        setLoading(false);
        getDataList(pagin.page_number, pagin.per_page)
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });

    setLimit(null)
  }

  async function getAgentTwod() {
    setAgentLoading(true)
    await GetDataFromServer(GAME_TWOD_AGENT_API, access_token)
      .then(({ data }) => {
        setTwodAGent(data)
        setAgentLoading(false)
      })
      .catch((error) => {
        setAgentLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getAgentTwod()
  }, [])

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_TWOD_AGENT_ONGOING_LIST_API,
      `page_number=${page_number}&per_page=${per_page}&order_type=DESC&order_by=created_at`,
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setList(
          result.map((value) => ({ ...value, twod: JSON.parse(value.twod) }))
        );
        setPagin({ ...pagin, total, page_number, per_page });
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function block2d(id) {
    setLoading(true);
    await UpdateDateOnServer(
      GAME_TWOD_AGENT_UPDATE_LIST_API,
      id,
      { close: true },
      access_token
    )
      .then(() => {
        getDataList();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Release Date",
      dataIndex: ["twod_section", "target_date"],
      align: "center",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Release Time",
      dataIndex: ["twod_section", "target_time"],
      align: "center",
    },
    {
      title: "Is Finish / Block",
      dataIndex: "is_finish",
      align: "center",
      render: (is_finish) => (
        <Tag>{is_finish ? "Block By Agent or Finish" : "Ongoing"}</Tag>
      ),
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title={`Are you sure Delet this Round ?`}
              onConfirm={() => {
                if (!user.is_finish) {
                  block2d(user.id);
                }
              }}
              disabled={user.is_finish}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size={ui_size}
                disabled={user.is_finish}
                style={{ width: "100px" }}
                type="primary"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <Popconfirm
              title={<Alert message={`Type new amount of Limit for this ${user.twod_section.target_time}`} type="info" showIcon />}
              description={<InputNumber min={100} onChange={(e) => setLimit(e)} style={{ width: '100%' }} value={limit} placeholder="Min amount = 1000" />}
              onConfirm={() => updateSetTimeLimit(user)}
              disabled={user.is_finish}
              okText="Yes"
              cancelText="No"
              placement="left"
              icon={null}
            >
              <Button
                disabled={user.is_finish}
                size={ui_size}
                type="primary"
              >
                Set Limit
              </Button>
            </Popconfirm>

          </Col>
          <Col>
            <Button
              disabled={user.is_finish}
              onClick={() => {
                setEdit(user);
                setShowForm(true);
              }}
              size={ui_size}
              type="primary"
            >
              Edit
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  const expendableColumns = [
    {
      title: "Number",
      dataIndex: "number",
      align: "center",
      render: (number, record) => <Text type={record.is_hot ? 'danger' : 'normal'}>{number}</Text>
    },
    {
      title: "Min Bit Amount",
      dataIndex: "min_bit",
      align: "center",
      render: (min_bit) => moneyFormat(min_bit),
    },
    // {
    //   title: "Max Bit Amount",
    //   dataIndex: "max_bit",
    //   align: "center",
    //   render: (max_bit) => moneyFormat(max_bit),
    // },
    {
      title: "Limit",
      dataIndex: "max",
      align: "center",
      render: (max) => moneyFormat(max),
    },
    {
      title: "Current Bit",
      dataIndex: "current",
      align: "center",
      render: (current) => moneyFormat(current),
    },
    {
      title: 'Multiply',
      dataIndex: 'multiply',
      align: 'center',
    },
    {
      title: "",
      render: (_, record) => (
        <Button size={ui_size} type="primary" onClick={() => setDetail(record)}>
          Detail
        </Button>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
  }

  console.log(twodAGent)

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col span={24}>
          <Spin spinning={agentLoading}>
            <Row justify={'space-between'}>
              <Col>
                <Tag bordered={false} color="success">Min 2D = {moneyFormat(twodAGent?.min_twod_amount || 0)}</Tag>
              </Col>
              <Col>
                <Tag bordered={false} color="green">Limit 2D = {moneyFormat(twodAGent?.max_twod_amount || 0)}</Tag>
              </Col>
              <Col>
                <Tag bordered={false} color="cyan">Min 3D = {moneyFormat(twodAGent?.min_threed_amount || 0)}</Tag>
              </Col>
              <Col>
                <Tag bordered={false} color="blue">Limit 3D = {moneyFormat(twodAGent?.max_threed_amount || 0)}</Tag>
              </Col>
              <Col>
                <Tag bordered={false} color="orange">Hot Multiply = {moneyFormat(twodAGent?.hotno_multiply || 0)}</Tag>
              </Col>
              <Col>
                <Tag bordered={false} color="red">Limit Hot = {moneyFormat(twodAGent?.hotno_limit || 0)}</Tag>
              </Col>
              <Col>
                <Button onClick={() => setShowAgent(true)} type="primary">Edit</Button></Col>
            </Row>
          </Spin>
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <div>
                    <Row justify={"end"} style={{ marginBottom: "12px" }}>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => setHead(record)}
                          icon={<VerifiedOutlined />}
                        >
                          ကာသီးတွက်ရန်
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      columns={expendableColumns}
                      dataSource={record.twod
                        .sort((a, b) => b.current - a.current)
                        .map(twod => {

                          const number = twod.key < 10 ? `0${twod.key}` : `${twod.key}`

                          if (record.is_finish) {
                            return {
                              ...twod,
                              number,
                              twod_agent_id: record.id
                            }
                          }

                          const hot_numbers = record.twod_section.hot_no?.split(',') || []

                          if (hot_numbers.length > 0 && hot_numbers.filter(hot => number.split('')[0] === hot).length > 0) {
                            return {
                              ...twod,
                              number,
                              twod_agent_id: record.id,
                              multiply: twodAGent ? twodAGent.hotno_multiply : 80,
                              is_hot: true
                            }
                          }

                          return record.twod_section.target_time === '12:01:00' || record.twod_section.target_time === '16:30:00'
                            ? {
                              ...twod,
                              number,
                              twod_agent_id: record.id,
                              multiply: 80
                            } : {
                              ...twod,
                              number,
                              twod_agent_id: record.id,
                              multiply: 85
                            }
                        })}
                      rowKey={(twodRecord) => `${record.id}_${twodRecord.key}`}
                    />
                  </div>
                );
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={"Update 2D"}
        destroyOnClose
        placement="right"
        width={"800px"}
      >
        <TwodEditFrom edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
      <Drawer
        open={!!detail}
        onClose={() => setDetail(false)}
        title="Detail"
        destroyOnClose={true}
        placement="right"
        width={"90vw"}
      >
        <TwoDetailPage data={detail} closeDrawer={setDetail} />
      </Drawer>
      <Drawer
        open={!!head}
        onClose={() => setHead(false)}
        title="ကာသီး"
        destroyOnClose={true}
        placement="right"
        width={"90vw"}
      >
        <TwodHeadPage data={head} closeDrawer={setHead} />
      </Drawer>
      <Drawer
        open={showAgent}
        onClose={() => setShowAgent(false)}
        title="Agent Data Edit"
        destroyOnClose={true}
        placement="right"
        width={"500px"}
      >{twodAGent ?
        <TwodAgentEditForm edit={twodAGent} closeDrawer={closeAGent} /> : ''}
      </Drawer>
    </>
  );
}
