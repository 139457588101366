import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataFromServer, GetDataListFromServer } from "../../lib/apis";
import { GAME_TWOD_AGENT_API, GAME_TWOD_THREED_TWOD_USER_LIST_APi } from "../../lib/config";
import { Button, Col, Input, message, Row, Table, Typography } from "antd";
import axios from "axios";

const { Text } = Typography;

export default function TwoDetailPage({ data, closeDrawer }) {
  const { access_token, id, ui_size, ...agent } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    user_code: "",
  });

  function numberHandler(key) {
    switch (true) {
      case key < 10:
        return `0${key}`;
        break;
      default:
        return `${key}`;
        break;
    }
  }

  async function getDataList(page_number, per_page, updateFilter) {
    let queryString = `page_number=${page_number}&per_page=${per_page}&twod_agent_id=${data.twod_agent_id
      }&order_by=created_at&order_type=DESC&number=${numberHandler(data.key)}`;

    if (updateFilter && updateFilter.user_code !== "") {
      queryString += `&user_code=${updateFilter.user_code}`;
    } else if (updateFilter && updateFilter.user_code === '') {
      // do nothing here
    } else if (filter.user_code !== "") {
      queryString += `&user_code=${filter.user_code}`;
    }

    setLoading(true);
    await GetDataListFromServer(
      GAME_TWOD_THREED_TWOD_USER_LIST_APi,
      queryString,
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        if (updateFilter) {
          setFilter(updateFilter);
        }
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "User Code",
      dataIndex: ["user", "user_code"],
    },
    {
      title: "Bit Amount",
      dataIndex: "bit_amount",
    },
    {
      title: "bit Wallet",
      dataIndex: "bit_wallet",
    },
    {
      title: "win Amount",
      dataIndex: "",
    },
    {
      title: "win_number",
      dataIndex: "win_number",
      render: (win_number) =>
        win_number === "00" ? "Not_finished" : win_number,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Row align={"middle"} style={{ marginBottom: "24px" }}>
          <Col span={4}>
            <Text>User Code :</Text>
          </Col>
          <Col span={4}>
            <Input
              value={filter.user_code}
              onChange={(e) =>
                setFilter({ ...filter, user_code: e.target.value })
              }
            />
          </Col>
          <Col span={16}>
            <Row justify={"end"} gutter={24}>
              <Col>
                <Button
                  loading={loading}
                  type="primary"

                  onClick={() => {
                    getDataList(1, 10, { user_code: "" });
                  }}
                >
                  Clear
                </Button>
              </Col>

              <Col>
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() => {
                    getDataList(1, 10);
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          size={ui_size}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{
            total: pagin.total,
            defaultCurrent: 1,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30, 50],
            onChange: (pageNumber, perPage) => {
              getDataList(pageNumber, perPage);
            },
          }}
          dataSource={list}
          loading={loading}
        />
      </Col>
    </Row>
  );
}
