import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, message, Row, Select } from "antd";
import { CreateDataOnServer } from "../../lib/apis";
import { GAME_TWOD_HOT_NUMBER_API } from "../../lib/config";

export default function TwodHotNumber({ data, closeDrawer }) {
  const [loading, setLoading] = useState(false)
  const { access_token } = useSelector(state => state.user)

  const NumbersArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

  async function twodHotNumber(formData) {
    setLoading(true)
    CreateDataOnServer(GAME_TWOD_HOT_NUMBER_API, { id: data.id, hot_no: formData.hot_no.length > 0 ? formData.hot_no.toString() : '' }, access_token)
      .then((res) => {
        setLoading(false)
        closeDrawer()
      }).catch((error) => {
        setLoading(false)
        message.error(error?.response?.data?.error || "Connection Error")
      })
  }

  return <Form
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 18 }}
    name="twod_hot_no_form"
    onFinish={twodHotNumber}
  initialValues={{
    hot_no: data.hot_no ? data.hot_no.split(',') : []
  }}
  >
    <Row justify={'end'}>
      <Col span={24}>
        <Form.Item name="hot_no" label="Hot ထိပ်စည်း">
          <Select
            optionFilterProp="label"
            mode="multiple"
            options={NumbersArr.map(value => ({
              value,
              label: value
            }))}
          />
        </Form.Item>
      </Col>
      <Col>
        <Button loading={loading} type="primary" htmlType="submit">Submit</Button></Col>
    </Row>
  </Form>

}