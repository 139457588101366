import { useState } from "react";
import { CreateDataOnServer } from "../../lib/apis";
import { GAME_TWOD_AGENT_UPDATE_API } from "../../lib/config";
import { Button, Col, Form, InputNumber, message, Row } from "antd";
import { useSelector } from "react-redux";

export default function TwodAgentEditForm({ edit, closeDrawer }) {
  const [loading, setLoading] = useState(false);
  const { ui_size, access_token } = useSelector((state) => state.user);

  async function editData(data) {
    setLoading(true)
    await CreateDataOnServer(GAME_TWOD_AGENT_UPDATE_API, data, access_token)
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return <Form
    labelCol={{ span: 18 }}
    wrapperCol={{ span: 6 }}
    name="agent_twod_form"
    onFinish={editData}
    initialValues={{
      min_twod_amount: edit.min_twod_amount,
      max_twod_amount: edit.max_twod_amount,
      min_threed_amount: edit.min_threed_amount,
      max_threed_amount: edit.max_threed_amount,
      hotno_multiply: edit.hotno_multiply,
      hotno_limit: edit.hotno_limit
    }}>
    <Row justify={"end"}>
      <Col span={24}>
        <Form.Item name="min_twod_amount" label="min 2d ( activate next 2 day )" rules={[{ required: true }]}>
          <InputNumber style={{ width: '100%' }} min={100} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="max_twod_amount" label="max 2d ( activate next 2 day )">
          <InputNumber style={{ width: '100%' }} min={100} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="min_threed_amount" label="min 3d ( activate next 2 day )">
          <InputNumber style={{ width: '100%' }} min={100} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="max_threed_amount" label="max 3d ( activate next 2 day )">
          <InputNumber style={{ width: '100%' }} min={100} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="hotno_multiply" label="Hot ထိပ်စည်း X% ( activate directly)">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="hotno_limit" label="Hot No. Max Limit ( activate directly)">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col>
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button></Col>
    </Row>
  </Form>
}