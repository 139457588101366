import { Button, Col, Form, Input, Row, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CreateDataOnServer } from "../../lib/apis";
import { USER_CREATE_API } from "../../lib/config";

export default function UserForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [profile_url, setProfile_url] = useState("");

  async function submitHandler(value) {
    setLoading(true);
    if (edit) {
      // await UpdateDateOnServer(
      //   CMS_AGENT_EDIT_API,
      //   edit.id,
      //   { ...value, profile_url, is_api_only: false },
      //   access_token
      // )
      //   .then(() => {
      //     setLoading(false);
      //     closeDrawer(true);
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     message.error(error?.response?.data?.error || "Connection Error");
      //   });
    } else {
      await CreateDataOnServer(
        USER_CREATE_API,
        {
          ...value,
          name: value.name,
          login_name: value.phone_no,
          payment_account: value.phone_no,
          created_by_agent: true
        },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  return (
    <Form
      variant="filled"
      form={form}
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="user_form"
      onFinish={submitHandler}
      initialValues={edit || {}}
    >
      <Row justify={"end"}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone_no"
            label="Kpay/Wave No"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          {!edit && (
            <>
              {/* <Form.Item
                name="login_name"
                label="User Login Name"
                validateDebounce={2000}
                rules={[
                  {
                    required: true,
                    message: "Please Input your User Name",
                  },
                  {
                    async validator(_, value) {
                      return await CmsLoginNameCheck(
                        USER_LOGIN_NAME_CHECK_APi,
                        value,
                        access_token
                      )
                        .then(({ data: { available } }) =>
                          available
                            ? Promise.resolve()
                            : Promise.reject("This User is Already Exist")
                        )
                        .catch((error) => {
                          return Promise.reject("This User is Already Exist");
                        });
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: "Password is Required" }]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
        </Col>
        <Col span={24}></Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
