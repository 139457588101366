import { useEffect, useState } from "react";
import {
  CreateDataOnServer,
  GetDataListFromServer,
  UpdateDateOnServer,
} from "../../lib/apis";
import {
  AGENT_PAYMENT_METHOD_LIST_API,
  AGENT_PROMOTION_LIST_API,
  AGENT_TRANSACTION_CREATE_API,
  AGENT_TRANSACTION_LIST_API,
  USER_TRANSACTION_UPDATE,
} from "../../lib/config";
import { moneyFormat, queryHandler } from "../../lib/globle_functions";
import {
  QUERY_AGENT_PAYMENT_METHOD,
  QUERY_AGENT_PROMOTION_DEPOSIT,
  QUERY_AGENT_TRANSACTION,
} from "../../lib/query_objects";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { updateUser } from "../../store/user";

const { Text } = Typography;

export default function UserTransactionList({ data, closeDrawer }) {
  const { access_token, ui_size, ...agent } = useSelector(
    (state) => state.user
  );
  const disaptch = useDispatch();
  const [agentPayments, setAgentPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [filter, setFilter] = useState({
    user_id: data.id,
    transaction_type: "",
    payment_type: "",
    transaction_id: "",
    payment_id: "",
    is_approve: "",
    is_reject: "",
    start_date: "",
    end_date: "",
    order_by: "created_at",
    get_total: "true",
    order_type: "DESC",
  });
  const [paging, setPaging] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [list, setList] = useState([]);
  const [agentPromo, setAgentPromos] = useState([]);
  const [note, setNote] = useState("");
  const [totalValue, setTotalValue] = useState({
    deposit: 0,
    withdraw: 0,
  });
  const [create, setCreate] = useState("");
  async function getAgentPromo(page_number, per_page) {
    await GetDataListFromServer(
      AGENT_PROMOTION_LIST_API,
      queryHandler(
        {
          name: "",
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_AGENT_PROMOTION_DEPOSIT,
        {
          ...paging,
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setAgentPromos(result);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function getAgentPayments(page_number, per_page) {
    await GetDataListFromServer(
      AGENT_PAYMENT_METHOD_LIST_API,
      queryHandler(
        {
          name: "",
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_AGENT_PAYMENT_METHOD,
        {
          ...paging,
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setAgentPayments(result);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function createDepositOrWithdraw(value) {
    const target = value.payment_id
      ? agentPayments.find((paym) => paym.id === value.payment_id)
      : false;
    setModelLoading(true);
    await CreateDataOnServer(
      AGENT_TRANSACTION_CREATE_API,
      {
        ...value,
        user_id: data.id,
        type: create,
        user_points: data.wallet?.point || 0,
        transaction_type: target ? target.account_type : value.payment_type,
      },
      access_token
    )
      .then(({ data: agentData }) => {
        // getList(paging.page_number, paging.per_page);
        setNote("");
        setModelLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setModelLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function getList(page_number, per_page, updateFilter) {
    await GetDataListFromServer(
      AGENT_TRANSACTION_LIST_API,
      queryHandler(
        updateFilter ? updateFilter : filter,
        QUERY_AGENT_TRANSACTION,
        {
          ...paging,
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: dataList, result, total } }) => {
        let totalData = { deposit: 0, withdraw: 0 };

        for (let i of result) {
          if (i.transaction_transaction_type === "DEPOSIT") {
            totalData.deposit = i.total_point;
          } else if (i.transaction_transaction_type === "WITHDRAW") {
            totalData.withdraw = i.total_point;
          }
        }

        setTotalValue(totalData);
        updateFilter && setFilter(updateFilter);
        setPaging({ ...paging, total, page_number, per_page });
        setList(dataList);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
        setLoading(false);
      });
  }

  useEffect(() => {
    getAgentPromo(1, 100);
  }, []);

  useEffect(() => {
    getList(paging.page_number, paging.per_page);
  }, []);

  useEffect(() => {
    getAgentPayments(1, 100);
  }, []);

  async function uploadHandler(record, targetPromo, type) {
    const data = {
      point: record.point,
      promo_point:
        targetPromo && record.accept_promo
          ? (record.point / 100) * targetPromo.points_percent
          : 0,
      is_approve: type === "accept",
      is_reject: type === "reject",
      note,
      user_points: record?.user?.wallet?.point || 0,
    };

    setLoading(true);
    await UpdateDateOnServer(
      USER_TRANSACTION_UPDATE,
      record.id,
      data,
      access_token
    )
      .then(({ data: agentData }) => {
        setNote("");
        // getList(paging.page_number, paging.per_page);
        closeDrawer();
      })
      .catch((error) => {
        setNote("");
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  function transactionHandler(key) {
    switch (true) {
      case key < 10:
        return `0000${key}`;
        break;
      case key < 100:
        return `000${key}`;
        break;
      case key < 1000:
        return `00${key}`;
        break;
      case key < 10000:
        return `0${key}`;
        break;
      default:
        return key;
        break;
    }
  }

  const columns = [
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      render: (type) => (
        <Text
          style={type === "DEPOSIT" ? { color: "green" } : { color: "red" }}
        >
          {type}
        </Text>
      ),
    },
    {
      title: "Transaction key",
      dataIndex: "id",
      render: (id) => transactionHandler(id),
    },
    {
      title: "Point",
      dataIndex: "points",
    },
    {
      title: "IS Approved",
      dataIndex: "is_approve",
      render: (_, record) => {
        if (record.reject_date) {
          return <Tag color="red">Rejected</Tag>;
        }

        if (record.approve_date) {
          return <Tag color="success">Approved</Tag>;
        }

        return <Tag color="warning">Waiting</Tag>;
      },
    },
    {
      title: "Date",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <Text>{moment(updated_at).format("DD-MM-YYYY")}</Text>
      ),
    },
    {
      title: "",
      render: (_, record) => {
        const targetPromo = record.accept_promo
          ? agentPromo.find(
              (value) =>
                value.max_deposit_amount >= record.point &&
                value.min_deposit_amount <= record.point
            )
          : false;
        return (
          <Row gutter={12}>
            <Col>
              <Popconfirm
                title="Accept Transaction"
                description={
                  <Row gutter={[12, 12]}>
                    <Col>Are you sure to Accept this Transaction ?</Col>
                    <Col span={24}>
                      <Text>Note :</Text>
                      <Input.TextArea
                        value={note}
                        onChange={(e) => setNote(e)}
                      />
                    </Col>
                  </Row>
                }
                onConfirm={() => uploadHandler(record, targetPromo, "accept")}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size={ui_size}
                  type="primary"
                  disabled={record.approve_date || record.reject_date}
                >
                  Accept
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Popconfirm
                title="Reject Transaction"
                description={
                  <Row gutter={[12, 12]}>
                    <Col>Are you sure to Reject this Transaction ?</Col>
                    <Col span={24}>
                      <Text>Note :</Text>
                      <Input.TextArea
                        value={note}
                        onChange={(e) => setNote(e)}
                      />
                    </Col>
                  </Row>
                }
                onConfirm={() => uploadHandler(record, targetPromo, "reject")}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size={ui_size}
                  type="primary"
                  danger
                  disabled={record.reject_date || record.approve_date}
                >
                  Reject
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  function expendableHandler(record) {
    const targetPromo = record.accept_promo
      ? agentPromo.find(
          (value) =>
            value.max_deposit_amount >= record.point &&
            value.min_deposit_amount <= record.point
        )
      : false;
    return (
      <Row gutter={24}>
        <Col span={12}>
          {record.transaction_recipt ? (
            <img
              style={{ width: "100%" }}
              src={record.transaction_recipt}
              alt="slip"
            />
          ) : (
            <div
              style={{
                width: "100%",
                border: "1px solid white",
                padding: "300px 0px",
                textAlign: "center",
              }}
            >
              No photo
            </div>
          )}
        </Col>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Text>Amount</Text>
            </Col>
            <Col span={16}>
              <Text>: {moneyFormat(record.points || 0)}</Text>
            </Col>
            {record.transaction_type === "FIRST_LOGIN" ? (
              <>
                <Col span={8}>
                  <Text>Type</Text>
                </Col>
                <Col span={16}>
                  <Text>: Promo</Text>
                </Col>
              </>
            ) : record.transaction_type === "DEPOSIT" ? (
              <>
                <Col span={8}>
                  <Text>Payment Type</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.paymentMethod?.account_type}</Text>
                </Col>
                <Col span={8}>
                  <Text>agent Account</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.paymentMethod?.name}</Text>
                </Col>
                <Col span={8}>
                  <Text>Agent Account No</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.paymentMethod?.account_no}</Text>
                </Col>
                <Col span={8}>
                  <Text>Request Promo</Text>
                </Col>
                <Col span={16}>
                  <Tag color={record.accept_promo ? "success" : "default"}>
                    {record.accept_promo ? "Yes" : "No"}{" "}
                    {targetPromo ? `${targetPromo.points_percent} %` : ""}
                  </Tag>
                </Col>
              </>
            ) : (
              <>
                <Col span={8}>
                  <Text>Request Payment</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.payment_type}</Text>
                </Col>
                <Col span={8}>
                  <Text>Account No</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.payment_account}</Text>
                </Col>
                <Col span={8}>
                  <Text>user Current Point</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.point}</Text>
                </Col>
                <Col span={8}>
                  <Text>User Total Deposit</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.total_deposit}</Text>
                </Col>
                <Col span={8}>
                  <Text>User Total Withdraw</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.total_withdraw}</Text>
                </Col>
                <Col span={8}>
                  <Text>Withdraw Band Point By Promotion</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.withdraw_band_point}</Text>
                </Col>
              </>
            )}
            <Col span={8}>
              <Text>TransactionId</Text>
            </Col>
            <Col span={16}>
              <Text copyable>{transactionHandler(record.id)}</Text>
            </Col>
            <Col span={8}>
              <Text>Approve Date</Text>
            </Col>
            <Col span={16}>
              <Text>{record.approve_date}</Text>
            </Col>
            <Col span={8}>
              <Text>Reject Date</Text>
            </Col>
            <Col span={16}>
              <Text>{record.reject_date}</Text>
            </Col>
            <Col span={8}>
              <Text>Note</Text>
            </Col>
            <Col span={16}>
              <Text>{record.note}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  function clearTransaction() {
    getList(paging.page_number, paging.per_page, {
      user_id: data.id,
      transaction_type: "",
      payment_type: "",
      payment_id: "",
      is_approve: "",
      is_reject: "",
      start_date: "",
      end_date: "",
      order_by: "created_at",
      get_total: "true",
      order_type: "DESC",
    });
  }

  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={5}>
                <Row>
                  <Col span={6}>
                    <Text>Type</Text>
                  </Col>
                  <Col span={18}>
                    <Select
                      size={ui_size}
                      style={{ width: "100%" }}
                      options={[
                        { label: "DEPOSIT", value: "DEPOSIT" },
                        { label: "WITHDRAW", value: "WITHDRAW" },
                        { label: "FIRST_LOGIN", value: "FIRST_LOGIN" },
                      ]}
                      value={filter.transaction_type}
                      onChange={(e) =>
                        setFilter({ ...filter, transaction_type: e })
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <Row>
                  <Col span={6}>
                    <Text>Id</Text>
                  </Col>
                  <Col span={18}>
                    <Input
                      onChange={(e) =>
                        setFilter({ ...filter, transaction_id: e.target.value })
                      }
                      value={filter.transaction_id}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={6}>
                    <Text>Date</Text>
                  </Col>
                  <Col span={18}>
                    <DatePicker.RangePicker
                      style={{ with: "100%" }}
                      format={"YYYY-MM-DD"}
                      value={[
                        filter.start_date !== ""
                          ? dayjs(filter.start_date, "YYYY-MM-DD")
                          : null,
                        filter.end_date !== ""
                          ? dayjs(filter.end_date, "YYYY-MM-DD")
                          : null,
                      ]}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          start_date: e[0],
                          end_date: e[1],
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row gutter={12} justify={"end"}>
                  <Col>
                    <Button
                      size={ui_size}
                      type="primary"
                      onClick={() => clearTransaction()}
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size={ui_size}
                      type="primary"
                      onClick={() => {
                        getList(paging.page_number, paging.per_page);
                      }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}></Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Row align={"middle"} justify={"space-evenly"}>
                      <Col>
                        <Text style={{ fontSize: "1.3rem", color: "green" }}>
                          Total Deposit = {moneyFormat(totalValue.deposit)}
                        </Text>
                      </Col>
                      <Col>
                        <Button
                          disabled={data.is_deleted}
                          type="primary"
                          size={ui_size}
                          onClick={() => setCreate("deposit")}
                        >
                          Create Deposit
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row align={"middle"} justify={"space-evenly"}>
                      <Col>
                        <Text style={{ fontSize: "1.3rem", color: "red" }}>
                          Total Withdraw = {moneyFormat(totalValue.withdraw)}
                        </Text>
                      </Col>
                      <Col>
                        <Button
                          size={ui_size}
                          disabled={data.is_deleted}
                          type="primary"
                          onClick={() => setCreate("withdraw")}
                        >
                          Create Withdraw
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Table
              size={ui_size}
              style={{ marginTop: "12px" }}
              columns={columns}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: (record) => expendableHandler(record),
              }}
              pagination={{
                total: paging.total,
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 30, 50],
                onChange: (pageNumber, perPage) => {
                  getList(pageNumber, perPage);
                },
              }}
              dataSource={list}
              loading={loading}
            />
          </Col>
        </Row>
      </Spin>
      <Modal
        open={create !== ""}
        onCancel={() => setCreate("")}
        destroyOnClose={true}
        footer={null}
        title={create}
      >
        <Row align={"middle"} justify={"end"} gutter={[12, 24]}>
          <Col span={12}>Agent Points = {agent.agent_points}</Col>
          <Col span={12}>User Points = {data.wallet?.point || 0}</Col>
          <Col span={24}>
            <Form
              onFinish={createDepositOrWithdraw}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              name="create_form"
            >
              <Form.Item
                label="Points"
                name="points"
                rules={[{ required: true }]}
              >
                <InputNumber
                  value={create.points}
                  max={
                    create === "deposit"
                      ? agent.agent_points
                      : data.wallet?.point || 0
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {create === "deposit" ? (
                <Form.Item
                  label="payment Account"
                  name="payment_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={agentPayments.map((value) => ({
                      label: value.name,
                      value: value.id,
                    }))}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "KPAY", value: "KPAY" },
                      { label: "WaVE", value: "VAVE" },
                    ]}
                  />
                </Form.Item>
              )}
              <Form.Item label="note" name="note">
                <Input />
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={modelLoading}
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
