import React from "react";
import { Card, Tag, Typography, Col, Row } from "antd";
import "./abc_table.css";
import { moneyFormat } from "../../lib/globle_functions";
import { UserOutlined } from "@ant-design/icons";

const { Text } = Typography;

const AbcGameDetail = (data) => {
  const { ...gameData } = data?.data;
  console.log("gameData:", gameData);

  const getImgSrc = (card) => `./cards/${card.card}.png`;

  function renderCards(cards, letter, players) {
    const playerData = players.find(
      (player) => player.id === data.data.user_id &&
      (player?.[`total_bit_${letter}`] != null ||
        player?.[`total_win_${letter}`] != null)
    );

    const totalBit = playerData?.[`total_bit_${letter}`] || 0;
    const totalWin = playerData?.[`total_win_${letter}`] || 0;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "30px",
        }}
        key={letter}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <Row
            justify="center"
            style={{
              background: "rgb(100, 116, 139)",
              borderRadius: "8px",
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
                Bit : {totalBit}
              </Text>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: "50px",
                fontWeight: "bold",
              }}
            >
              {letter}
            </Text>
          </div>
          <Row
            justify="center"
            style={{
              background: "rgb(100, 116, 139)",
              borderRadius: "8px",
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
                Win : {totalWin}
              </Text>
            </Col>
          </Row>
        </Card>
        {cards && (
          <div className="img-container">
            {Object.keys(cards).map((cardKey) => {
              const card = cards[cardKey];
              return (
                card?.card && (
                  <img
                    key={cardKey}
                    src={getImgSrc(card)}
                    alt="card-img"
                    style={{ width: "48px" }}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    );
  }

  function renderDealerCards(cards) {
    return (
      <div>
        {cards && (
          <div className="img-container">
            {Object.keys(cards).map((key) => {
              const card = cards[key];
              return (
                card?.card && (
                  <img
                    key={key}
                    src={getImgSrc(card)}
                    alt="dealer-card"
                    style={{ width: "48px" }}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    );
  }

  console.log(data)

  return (
    <div>
      <Text>Record_ID</Text> = <Text copyable>{gameData?.id}</Text>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <div className="abc_table">
          <div className="table">
            {["A", "B", "C", "D"].map((letter) =>
              renderCards(
                gameData.data?.[`${letter}_cards`],
                letter,
                gameData.data?.players
              )
            )}

            <div className="player_container">
              {gameData.data?.players &&
                gameData.data?.players.filter(player=>player.id === data.data.user_id).map((player) => (
                  <div
                    key={player.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      borderRadius: "24px",
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          width: "60px",
                          height: "60px",
                          backgroundColor: "#ddd",
                        }}
                      >
                        <UserOutlined
                          style={{
                            color: "black",
                            fontSize: "28px",
                            opacity: "0.8",
                          }}
                        />
                      </div>
                      <Text
                        strong
                        style={{
                          fontSize: "16px",
                          color: "#333",
                          marginBottom: "4px",
                          display: "block",
                        }}
                      >
                        {player?.info.name}
                      </Text>
                    </div>
                    <div>
                      <div
                        style={{
                          padding: "8px",
                          borderRadius: "16px",
                          backgroundColor: "#e6f7ff",
                          color: "#0050b3",
                          fontSize: "14px",
                          marginBottom: "4px",
                          textAlign: "center",
                          fontWeight: "bold",
                          boxShadow: "inset 0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        Original = {moneyFormat(player?.balance_origin)}
                      </div>
                      <div
                        style={{
                          padding: "8px",
                          borderRadius: "16px",
                          backgroundColor: "#e6ffec",
                          color: "#389e0d",
                          fontSize: "14px",
                          boxShadow: "inset 0 4px 8px rgba(0, 0, 0, 0.1)",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Final = {moneyFormat(player?.balance)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="dealer_container">
              <Tag
                style={{
                  width: "100px",
                  textAlign: "center",
                  margin: "0 auto",
                }}
                color="#d0d315"
              >
                Dealer
              </Tag>

              <div>{renderDealerCards(gameData.data?.Dealer_cards)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbcGameDetail;
