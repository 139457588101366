const PERMISSION_CONST = [
  {
    label: "CMS Users",
    color: "processing",
    keys: [
      { name: "Cms User List View", key: "CMS_USER_READ" },
      { name: "Cms User Create", key: "CMS_USER_CREATE" },
      { name: "Cms User Edit", key: "CMS_USER_UPDATE" },
      { name: "Cms User Delete", key: "CMS_USER_DELETE" },
      { name: "Cms User Password Reset", key: "CMS_USER_CREDENTIAL_RESET" },
    ],
  },
  {
    label: "CMS Access Permissions",
    color: "success",
    keys: [
      { name: "Access Permissions List View", key: "ACCESS_PERMISSION_READ" },
      { name: "Access Permissions Create", key: "ACCESS_PERMISSION_CREATE" },
      { name: "Access Permissions Edit", key: "ACCESS_PERMISSION_UPDATE" },
      { name: "Access Permissions Delete", key: "ACCESS_PERMISSION_DELETE" },
    ],
  },
  {
    label: "Agents Controller",
    color: "purple",
    keys: [
      { name: "Agent List View", key: "CMS_AGENT_READ" },
      { name: "Agent Create", key: "CMS_AGENT_CREATE" },
      { name: "Agent Edit", key: "CMS_AGENT_UPDATE" },
      { name: "Agent Delete", key: "CMS_AGENT_DELETE" },
      { name: "Agent Password Reset", key: "CMS_AGENT_CREDENTIAL_RESET" },
      { name: "Block an Agent", key: "CMS_AGENT_BLOCK" },
    ],
  },
  {
    label: "Payment Methods",
    color: "gold",
    keys: [
      { name: "Payment Method List View", key: "CMS_PAYMENT_METHOD_READ" },
      { name: "Payment Method Create", key: "CMS_PAYMENT_METHOD_CREATE" },
      { name: "Payment Method Delete", key: "CMS_PAYMENT_METHOD_DELETE" },
    ],
  },
  {
    label: "Transactions",
    color: "red",
    keys: [
      { name: "Transactions Create", key: "CMS_TRANSACTION_CREATE" },
      { name: "Transactions Read", key: "CMS_TRANSACTION_READ" },
    ],
  },
  {
    label: "Utility Avatar",
    color: "magenta",
    keys: [
      { name: "Utility Avatar List View", key: "UTILITY_AVATAR_READ" },
      { name: "Utility Avatar Create", key: "UTILITY_AVATAR_CREATE" },
      { name: "Utility Avatar Delete", key: "UTILITY_AVATAR_DELETE" },
    ],
  },
  {
    label: "Games TwoD/Threed",
    color: "volcano",
    keys: [
      { name: "TwoD threeD List View", key: "TWOD_THREED_READ" },
      { name: "TwoD threeD Create", key: "TWOD_THREED_CREATE" },
      { name: "TwoD threeD Delete", key: "TWOD_THREED_DELETE" },
    ],
  },
  {
    label: "Games Ball",
    color: "volcano",
    keys: [
      { name: "Ball List View", key: "BALL_READ" },
      { name: "Ball Create", key: "BALL_CREATE" },
      { name: "Ball Delete", key: "BALL_DELETE" },
    ],
  },
  {
    label: "Games Shan",
    color: "volcano",
    keys: [
      { name: "Shan List View", key: "SHAN_READ" },
      { name: "Shan Create", key: "SHAN_CREATE" },
      { name: "Shan Delete", key: "SHAN_DELETE" },
    ],
  },
  {
    label: "Games PP",
    color: "volcano",
    keys: [
      { name: "PP List View", key: "PP_READ" },
      { name: "PP Create", key: "PP_CREATE" },
      { name: "PP Delete", key: "PP_DELETE" },
    ],
  },{
    label: "Games ABC",
    color: "volcano",
    keys: [
      { name: "ABC List View", key: "ABC_READ" },
      { name: "ABC Create", key: "ABC_CREATE" },
      { name: "ABC Delete", key: "ABC_DELETE" },
    ],
  },
  {
    label:"Games AORG",
    color:'green',
    keys:[
      { name: "AORG List View", key: "AORG_READ" },
      { name: "AORG Create", key: "AORG_CREATE" },
      { name: "AORG Delete", key: "AORG_DELETE" },
    ]
  }
];

const PERMISSION_COLORS = {
  CMS_USER_READ: "processing",
  CMS_USER_CREATE: "processing",
  CMS_USER_UPDATE: "processing",
  CMS_USER_DELETE: "processing",
  CMS_USER_CREDENTIAL_RESET: "processing",
  ACCESS_PERMISSION_READ: "success",
  ACCESS_PERMISSION_CREATE: "success",
  ACCESS_PERMISSION_UPDATE: "success",
  ACCESS_PERMISSION_DELETE: "success",
  CMS_AGENT_CREATE: "purple",
  CMS_AGENT_UPDATE: "purple",
  CMS_AGENT_DELETE: "purple",
  CMS_AGENT_CREDENTIAL_RESET: "purple",
  CMS_AGENT_BLOCK: "purple",
  CMS_AGENT_READ: "purple",
  CMS_PAYMENT_METHOD_CREATE: "gold",
  CMS_PAYMENT_METHOD_READ: "gold",
  CMS_PAYMENT_METHOD_DELETE: "gold",
  CMS_TRANSACTION_CREATE: "red",
  CMS_TRANSACTION_READ: "red",
  UTILITY_AVATAR_CREATE: "magenta",
  UTILITY_AVATAR_READ: "magenta",
  UTILITY_AVATAR_DELETE: "magenta",
  TWOD_THREED_CREATE :"volcano",
  TWOD_THREED_READ :"volcano",
  TWOD_THREED_DELETE :"volcano",
  BALL_CREATE :"success",
  BALL_READ :"success",
  BALL_DELETE :"success",
  SHAN_CREATE :"purple",
  SHAN_READ :"purple",
  SHAN_DELETE :"purple",
  PP_CREATE :"processing",
  PP_READ :"processing",
  PP_DELETE :"processing",
  ABC_CREATE :"gold",
  ABC_READ :"gold",
  ABC_DELETE :"gold",
  AORG_CREATE :"success",
  AORG_READ :"success",
  AORG_DELETE :"success",
};

export { PERMISSION_CONST, PERMISSION_COLORS };
