import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import { UpdateServerData } from '../../lib/apis';
import { GAME_BALL_IMPORT_UPDATE_MATCH_API } from '../../lib/config';

const { TextArea } = Input;

const UpdateListForm = ({ closeDrawer }) => {
  const { access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (val) => {
    setLoading(true);
    const data = JSON.parse(val.content);

    if (data.matches.length > 0) {
      const dataFormat = data.matches.map((match) => ({
        refId: match.matchId,
        homeUpper: match.homeUpper,
        awayScore: match.awayScore,
        goalTotal: match.goalTotal,
        goalTotalPrice: match.goalTotalPrice,
        startTime: match.startTime,
        odds: match.odds,
        price: match.price,
        highTax: match.highTax,
        canceled: match.canceled,
      }));

      console.log('format data:', dataFormat);

      await UpdateServerData(
        GAME_BALL_IMPORT_UPDATE_MATCH_API,
        dataFormat,
        access_token
      )
        .then((res) => {
          setLoading(false);
          closeDrawer(true);
          message.success('Successfull Updated!');
          console.log('Updated data:', res.data);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || 'Connection Error');
        });
    }
  };
  return (
    <Form onFinish={handleSubmit}>
      <Form.Item name="content">
        <TextArea
          rows={4}
          placeholder="Enter"
          style={{ height: '500px', overflowY: 'auto', resize: 'none' }}
        />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdateListForm;
