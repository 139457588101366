import { Button, Col, Form, InputNumber, Row, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UpdateDateOnServer } from "../../lib/apis";
import { GAME_TWOD_AGENT_UPDATE_LIST_API } from "../../lib/config";
import { Select } from "antd";

export default function TwodEditFrom({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let twoD = [];

  for (let i = 0; i < 100; i++) {
    twoD.push(i);
  }

  async function submitHandler(value) {
    let data = {
      id: edit.id,
      twod: edit.twod,
    };

    if (value.update?.length > 0 && value.update_amount >= 100) {
      for (let i of value.update) {
        const targetNumber = data.twod[i];
        data.twod[i] = {
          ...targetNumber,
          max: value.update_amount,
          max_bit: value.update_amount
        };
      }
    }

    if (value.block?.length > 0) {
      for (let i of value.block) {
        const targetNumber = data.twod[i];
        data.twod[i] = { ...targetNumber, max: 0 };
      }
    }

    setLoading(true);
    await UpdateDateOnServer(
      GAME_TWOD_AGENT_UPDATE_LIST_API,
      edit.id,
      data,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Form
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="twod_edit_form"
      form={form}
      onFinish={submitHandler}
    >
      <Row justify={"end"}>
        <Col span={24}>
          <Form.Item name="update" label="Maximum Amount Update Numbers">
            <Select
              mode="multiple"
              optionFilterProp="label"
              options={twoD.map((_, index) => ({
                value: index,
                label: index < 10 ? `0${index}` : `${index}`,
              }))}
            />
          </Form.Item>

          <Form.Item
            dependencies={["update"]}
            rules={[
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    if (
                      getFieldValue("update") &&
                      getFieldValue("update").length > 0 &&
                      !value
                    ) {
                      return Promise.reject(new Error("If Update Numbers. Amount is Required"));
                    }
                    return Promise.resolve();
                  },
                };
              },
            ]}
            name="update_amount"
            label="Maximum Amount"
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="block" label="Block Numbers">
            <Select
              optionFilterProp="label"
              mode="multiple"
              options={twoD.map((_, index) => ({
                value: index,
                label: index < 10 ? `0${index}` : `${index}`,
              }))}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
