import axios from "axios";
import { CMS_AGENT_LOGIN_API, CMS_LOGIN_API } from "./config";
import { requestHeader } from "./globle_functions";

function CmsLogin(credential) {
  return axios.post(`${CMS_LOGIN_API}`, credential);
}

function AgentLogin(credential) {
  return axios.post(`${CMS_AGENT_LOGIN_API}`, credential);
}

function CmsLoginNameCheck(prefix, id, access_token) {
  return axios.get(`${prefix}/${id}`, requestHeader(access_token));
}

function checkAgentPoint(prefix,access_token) {
  return axios.get(`${prefix}`,requestHeader(access_token))
}

function agentUserLoginNameCheck(prefix, login_name, access_token) {
  return axios.get(`${prefix}/${login_name}`, requestHeader(access_token));
}

function GetDataListFromServer(prefix, query, access_token) {
  return axios.get(`${prefix}?${query}`, requestHeader(access_token));
}

function GetDataFromServer(prefix, access_token) {
  return axios.get(`${prefix}`, requestHeader(access_token));
}

function CreateDataOnServer(prefix, data, access_token) {
  return axios.post(`${prefix}`, data, requestHeader(access_token));
}

function UpdateDateOnServer(prefix, id, data, access_token) {
  return axios.patch(`${prefix}/${id}`, data, requestHeader(access_token));
}

function UpdateServerData(prefix, data, access_token) {
  return axios.patch(`${prefix}`, data, requestHeader(access_token));
}


function DeleteDataOnServer(prefix, id, access_token) {
  return axios.delete(`${prefix}/${id}`, requestHeader(access_token));
}

function UpdateSingleDataOnServer(prefix, id, access_token) {
  return axios.put(`${prefix}/${id}`, {}, requestHeader(access_token));
}

function UpdateCancelOnServer(prefix, id, access_token) {
  return axios.patch(`${prefix}/${id}`, {}, requestHeader(access_token));
}

function getListFromServer(prefix, access_token) {
  return axios.get(`${prefix}`, requestHeader(access_token));
}

function GetBallWinCut(prefix, access_token) {
  return axios.get(`${prefix}`, requestHeader(access_token));
}

function getAgentBody(prefix, access_token) {
  return axios.get(`${prefix}`, requestHeader(access_token));
}

export {
  CmsLogin,
  AgentLogin,
  CmsLoginNameCheck,
  GetDataListFromServer,
  CreateDataOnServer,
  UpdateDateOnServer,
  UpdateServerData,
  DeleteDataOnServer,
  UpdateSingleDataOnServer,
  UpdateCancelOnServer,
  agentUserLoginNameCheck,
  GetDataFromServer,
  GetBallWinCut,
  getListFromServer,
  getAgentBody,
  checkAgentPoint
};
