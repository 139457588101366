import React from "react";
import {
  Descriptions,
  Typography,
  Divider,
  Row,
  Col,
  Card,
  Image,
  Tag,
} from "antd";
import moment from "moment";

const { Text } = Typography;

const DescriptionItem = ({ label, value, itemStyle }) => (
  <Descriptions style={itemStyle} column={1}>
    <Descriptions.Item label={<Text className="text-success">{label}</Text>}>
      <Text>{value}</Text>
    </Descriptions.Item>
  </Descriptions>
);

const renderTeamDetails = (team, isKeyTeam) => (
  <Col
    span={8}
    style={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Image src={team?.logo} width={56} preview={false} />
    <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
      <div style={{ flex: 1 }}>
        <Text>{team?.name || "...."}</Text>
      </div>
      <div style={{ marginLeft: "8px" }}>
        {isKeyTeam && <Text className="text-success">{team?.keyMatch}</Text>}
      </div>
    </div>
  </Col>
);
const BallBodyDetail = ({ data }) => {
  console.log("ball body:", data);

  const keyTeamName =
    data.team_id === data.teamOne.id
      ? data.teamOne.name_en
      : data.teamTwo.name_en;

  const formatDateTime = (dateTime, type) => {
    return type === "date"
      ? moment(dateTime).format("MMM DD YYYY")
      : moment(dateTime).format("h:mm A");
  };

  const goalOddChanges = () => {
    const goals = [
      data.goal_plus_one,
      data.goal_plus_two,
      data.goal_plus_three,
    ];
    const validGoals = goals.every((goal) => goal !== null);
    return validGoals ? goals.join("") : data.match?.goal_plus;
  };

  const keyOddChanges = () => {
    const keys = [data.key_one, data.key_two, data.key_three];
    const validKeys = keys.every((key) => key !== null);
    return validKeys ? keys.join("") : data.match.key;
  };

  return (
    <div>
      <DescriptionItem label="ID" value={data.id} />
      <DescriptionItem label="Game Type" value="Body" />
      <DescriptionItem label="Tax" value={data.win_cut_percent} />

      <DescriptionItem
        label="Is Finish?"
        value={data.is_finish ? "Yes" : "No"}
      />
      <DescriptionItem
        label="Match Cancel"
        value={data.match.cancel_match ? "Yes" : "No"}
      />
      <DescriptionItem
        label="Final Point"
        value={data.match.cancel_match ? data.bit_amount : data.final_point}
        itemStyle={{ marginTop: "30px" }}
      />
      <DescriptionItem label="Bit Amount" value={data.bit_amount} />
      <DescriptionItem
        label="Match Date"
        value={formatDateTime(data.match.display_date, "date")}
      />
      <DescriptionItem
        label="Match Time"
        value={formatDateTime(data.match.display_date, "time")}
      />
      <DescriptionItem
        label="Bet Time"
        value={formatDateTime(data.created_at, "time")}
      />

      <Card className="bg-card mt-5">
        <Row align="middle">
          {renderTeamDetails(
            {
              logo: data.teamOne.logo,
              name: data.teamOne.name_en,
              keyMatch: keyOddChanges(),
            },
            data.match.key_team === data.teamOne.id
          )}
          <Col
            span={8}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Tag
              className="goal-card py-1 px-2 rounded-lg"
              style={{ fontSize: "16px" }}
            >
              {data.match.team_one_goal} : {data.match.team_two_goal}
            </Tag>
            <Tag
              className="bg-success py-1 px-2 rounded-lg"
              style={{ marginTop: "8px" }}
            >
              {goalOddChanges()}
            </Tag>
          </Col>

          {renderTeamDetails(
            {
              logo: data.teamTwo.logo,
              name: data.teamTwo.name_en,
              keyMatch: keyOddChanges(),
            },
            data.match.key_team === data.teamTwo.id
          )}
        </Row>
        <Divider style={{ borderColor: "white" }} className="my-4" />
        <Row>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Text className="text-success">Selected</Text>
            {data.bet_type === "KEY" && (
              <Text style={{ marginTop: "5px" }}>{keyTeamName}</Text>
            )}
            {data.bet_type === "GOAL_UP" &&
              data.team_id === data.teamOne.id && <Text>GOAL_UP</Text>}
            {data.bet_type === "GOAL_UP" &&
              data.team_id !== data.teamOne.id && <Text>GOAL_DOWN</Text>}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default BallBodyDetail;
