import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreateDataOnServer, GetDataListFromServer, UpdateDateOnServer } from "../lib/apis";
import { GAME_TWO_THREED_TWOD_MANUAL_API, GAME_TWO_THREED_TWOD_ONGOING_API } from "../lib/config";
import { QUERY_TWOD_ONGOING } from "../lib/query_objects";
import dayjs from "dayjs";
import {
  Button,
  Col,
  Drawer,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import { queryHandler } from "../lib/globle_functions";
import { render } from "@testing-library/react";
import TwodHotNumber from "../components/game_twod_threed/twodHotNoForm";

const { Text } = Typography;

export default function GameTwodOngoingList() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    is_finish: "",
  });
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [showTwo, setShowTwo] = useState(false)

  function closeDrawer() {
    setShowTwo(false)
    getDataList(pagin.page_number, pagin.per_page);
  }

  async function finishSection(id, target_time, target_date) {
    CreateDataOnServer(GAME_TWO_THREED_TWOD_MANUAL_API, { id, number, target_time }, access_token)
      .then((res) => {
        setNumber('')
        getDataList(pagin.page_number, pagin.per_page);
      }).catch((error) => message.error(error?.response?.data?.error || "Connection Error"))
  }

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_TWO_THREED_TWOD_ONGOING_API,
      queryHandler(filter, QUERY_TWOD_ONGOING, { page_number, per_page }),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "target_date",
      align: "center",
      render: (target_date) => dayjs(target_date).format("YYYY-MM-DD"),
    },
    {
      title: "Time",
      dataIndex: "target_time",
      align: "center",
    },
    {
      title: "Win Number",
      dataIndex: "win_number",
      align: "center",
      render: (win_number) => (
        <Text>{win_number === "000" ? "---" : win_number}</Text>
      ),
    },
    {
      title: "Is Finish",
      dataIndex: "is_finish",
      align: "center",
      render: (is_finish) => <Text>{is_finish ? "Yes" : "No"}</Text>,
    },
    {
      title: 'Hot Numbers',
      dataIndex: 'hot_no',
      align: 'center',
      render: (hot_no) => <Text>{hot_no ? hot_no.toString() : ''}</Text>
    },
    {
      title: '',
      dataIndex: 'hot_no',
      align: 'center',
      render: (_, record) => record.target_time === '12:01:00' ? <Button disabled={record.is_finish} onClick={() => setShowTwo(record)} type="primary">Edit Hot No</Button> : ''
    },
    {
      title: "",
      dataIndex: "is_finish",
      align: "center",
      render: (_, record) =>
        record.target_time === "12:01:00" ||
          record.target_time === "16:30:00" ? (
          <Row>
            <Col>
              <Popconfirm
                title="Finish 2D"
                description={
                  <Input
                    value={number}
                    placeholder="Input Win Number"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                }
                onConfirm={() =>
                  finishSection(
                    record.id,
                    record.target_time,
                    record.target_date
                  )
                }
              >
                <Button
                  type="primary"
                  size={ui_size}
                  disabled={
                    !permissions.includes("TWOD_THREED_CREATE") ||
                    record.is_finish
                  }
                >
                  Finish Section
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        ) : (
          <div></div>
        ),
    },
  ];

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer open={!!showTwo}
        onClose={() => setShowTwo(false)}
        title="Hot ထိပ်စည်း"
        destroyOnClose={true}
        placement="right"
        width={'700px'}
      >
        <TwodHotNumber data={showTwo} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
